import logo from './logo.svg';
import './App.css';
import Greet from './components/Greet';
import Welcome from './components/Welcome';

function App() {
  return (
    <div className="App">
      <Greet name='Dan' favTeam='Texas A&M'/>
      <Greet name='Steph' favTeam='TCU'/>
      <Greet name='Megan'favTeam='TCU'/>
      <Welcome />
    </div>
  );
}

export default App;
