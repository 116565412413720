import React from "react";

// function Greet() {
//     return <h1>Welcome Dan</h1>
// }

const Greet =  (props) => {
    console.log(props)
     return <h1>Welcome {props.name} Team: {props.favTeam}</h1>
} 

export default Greet;